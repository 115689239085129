<!--
 * @Description: 重装
 * @Author: ChenXueLin
 * @Date: 2022-02-11 11:28:41
 * @LastEditTime: 2022-10-13 17:07:05
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <!-- 补录信息 -->
    <el-dialog
      v-dialogDrag
      title="补录信息"
      :visible.sync="auditVisible"
      width="1100px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      v-loading="dialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="add-concat-dialog"
    >
      <div class="audit-content">
        <el-form :inline="true" label-width="100px">
          <el-form-item label="原场景名称">
            {{ basicInfo.sceneName }}
          </el-form-item>
          <el-form-item label="原场景类型">
            {{ basicInfo.sceneTypeName }}
          </el-form-item>
        </el-form>
        <el-form
          ref="auditForm"
          label-width="100px"
          :model="auditForm"
          :rules="auditFormRules"
          :inline="true"
        >
          <el-form-item label="工程师" prop="engineerrpName">
            <el-input
              v-model.trim="basicInfo.engineerrpName"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="作业时间" prop="finishTime">
            <el-date-picker
              v-model="auditForm.finishTime"
              type="datetime"
              placeholder="选择日期"
              value-format="timestamp"
              popper-class="special"
              :disabled="basicInfo.fixDateStr ? true : false"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            label="新场景名称"
            prop="sceneName"
            v-if="!hiddenForm && basicInfo.isAllRemove != 1"
          >
            <el-input
              placeholder="新场景名称"
              v-model="auditForm.sceneName"
            ></el-input>
            <!--:disabled="basicInfo.newSceneName ? true : false" -->
          </el-form-item>
          <el-form-item
            label="车架号"
            prop="vin"
            v-if="!hiddenForm && basicInfo.isAllRemove != 1"
            :rules="
              basicInfo.needVin
                ? [
                    {
                      required: true,
                      message: '请输入车架号',
                      trigger: ['blur', 'change']
                    },
                    { validator: checkIdvin, trigger: 'blur' }
                  ]
                : {}
            "
          >
            <el-input
              v-model.trim="auditForm.vin"
              placeholder="车架号"
              :disabled="
                this.basicInfo.taskItemOtherInfoRespVO.vin ? true : false
              "
            ></el-input>
          </el-form-item>
          <el-form-item
            label="车牌颜色"
            prop="plateColor"
            v-if="!hiddenForm && basicInfo.isAllRemove != 1"
          >
            <e6-vr-select
              :disabled="basicInfo.plateColor ? true : false"
              v-model="auditForm.plateColor"
              :data="colorList"
              placeholder="车牌颜色"
              title="车牌颜色"
              @change="handleColor"
              :props="{
                id: 'colorId',
                label: 'colorName'
              }"
              virtual
              clearable
              :is-title="true"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item
            label="车辆品牌"
            prop="vehicleBrand"
            v-if="!hiddenForm && basicInfo.isAllRemove != 1"
          >
            <e6-vr-select
              :disabled="
                this.basicInfo.taskItemOtherInfoRespVO.vehicleBrand
                  ? true
                  : false
              "
              @change="handleVehicleBrand"
              v-model="auditForm.vehicleBrand"
              :data="carBrandList"
              placeholder="车辆品牌"
              title="车辆品牌"
              clearable
              :props="{
                id: 'brandId',
                label: 'brandName'
              }"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item
            label="车型分类"
            prop="vehicleMarker"
            v-if="!hiddenForm && basicInfo.isAllRemove != 1"
          >
            <e6-vr-select
              :disabled="
                this.basicInfo.taskItemOtherInfoRespVO.vehicleMarker
                  ? true
                  : false
              "
              @change="handleVehicleMarker"
              v-model="auditForm.vehicleMarker"
              :data="vehicleMarkerList"
              placeholder="车型分类"
              title="车型分类"
              clearable
              :props="{
                id: 'codeValue',
                label: 'codeName'
              }"
            ></e6-vr-select>
          </el-form-item>

          <el-form-item
            label="车体长"
            prop="overallLength"
            v-if="!hiddenForm && basicInfo.isAllRemove != 1"
          >
            <el-input
              :disabled="
                this.basicInfo.taskItemOtherInfoRespVO.overallLength
                  ? true
                  : false
              "
              v-model.trim="auditForm.overallLength"
              placeholder="车体长"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="车体宽"
            prop="overallWidth"
            v-if="!hiddenForm && basicInfo.isAllRemove != 1"
          >
            <el-input
              :disabled="
                this.basicInfo.taskItemOtherInfoRespVO.overallWidth
                  ? true
                  : false
              "
              v-model.trim="auditForm.overallWidth"
              placeholder="车体宽"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="车体高"
            prop="overallHeight"
            v-if="!hiddenForm && basicInfo.isAllRemove != 1"
          >
            <el-input
              :disabled="
                this.basicInfo.taskItemOtherInfoRespVO.overallHeight
                  ? true
                  : false
              "
              v-model.trim="auditForm.overallHeight"
              placeholder="车体高"
            ></el-input>
          </el-form-item>
        </el-form>

        <!-- 设备明细 -->
        <template>
          <div class="detail-title">设备明细</div>
          <el-table :data="equipDetailTableData">
            <el-table-column
              show-overflow-tooltip
              v-for="(column, index) in columnData"
              :key="index"
              :prop="column.fieldName"
              :label="column.fieldLabel"
              :min-width="column.width"
              :fixed="column.fixed"
              :align="column.align"
              header-align="center"
            >
              <template slot-scope="{ row }">
                <span v-if="column.fieldName == 'equipOp'">
                  <e6-vr-select
                    v-model="row.equipOp"
                    :data="equipOpList"
                    placeholder="设备操作"
                    title="设备操作"
                    :props="{
                      id: 'codeValue',
                      label: 'codeName'
                    }"
                    :disabled="
                      row.isReadOnly == 1 || basicInfo.isAllRemove == 1
                    "
                    @change="handleEquip(row)"
                  ></e6-vr-select>
                </span>
                <!--  :disabled="row.equType == 1" -->
                <span
                  v-else-if="column.fieldName == 'cardOp' && row.equType == 1"
                >
                  <e6-vr-select
                    v-model="row.cardOp"
                    :data="cardOpList"
                    placeholder="卡操作"
                    title="卡操作"
                    :props="{
                      id: 'codeValue',
                      label: 'codeName'
                    }"
                    :disabled="
                      row.isReadOnly == 1 || basicInfo.isAllRemove == 1
                    "
                    @change="
                      (val, node) => {
                        handleCard(val, node, row);
                      }
                    "
                  ></e6-vr-select>
                </span>

                <span
                  v-else-if="
                    column.fieldName == 'simNo' &&
                      (row.cardOp == 3 || row.cardOp == 2)
                  "
                >
                  <el-button
                    type="text"
                    @click="handleSim(row)"
                    :disabled="row.isReadOnly == 1"
                    v-if="!basicInfo.isGrayscaleWork"
                    >{{ row.simNo ? row.simNo : "请选择SIM卡号" }}</el-button
                  >
                  <el-input
                    v-model.trim="row.simNo"
                    placeholder="请输入SIM卡号"
                    v-else
                    :disabled="row.isReadOnly == 1"
                  ></el-input>
                </span>
                <span v-else>{{ row[column.fieldName] }}</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <!-- 作业图片 -->
        <template>
          <div class="title">上传凭证</div>
          <div class="img-content">
            <div class="img-item" v-for="(item, index) in imgList" :key="index">
              <uploadOBS
                class="avatar-uploader"
                :fileType="2"
                :limitSize="10"
                :showFileList="false"
                :showBtnType="0"
                :canRemove="false"
                :limitConfig="['jpeg', 'png', 'bmp', 'gif', 'jpg']"
                @success="
                  res => {
                    handleAvatarSuccess(res, item);
                  }
                "
              >
                <div slot="preview">
                  <img
                    v-if="item.fileUrl"
                    :src="item.fileUrl"
                    class="avatar"
                    :preview-src-list="item.fileUrl"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </div>
              </uploadOBS>
              <div class="img-title">{{ item.photoPositionName }}</div>
            </div>
          </div>
        </template>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
        <el-button
          type="primary"
          @click="sendBack"
          v-if="!basicInfo.fixDate && basicInfo.itemStatus == 7"
          >回到未分配</el-button
        >
      </div>
    </el-dialog>

    <!-- 添加费用 -->
    <add-equip-fee
      :addFeeVisible="addFeeVisible"
      @closeDialog="closeDialog"
      @updateFee="updateFee"
    ></add-equip-fee>
    <!-- 选择sim卡 -->
    <select-sim
      :engineerrpId="basicInfo.engineerrpId"
      :simVisible="simVisible"
      @closeSim="closeSim"
      @selectSim="selectSim"
      v-if="simVisible"
    ></select-sim>
  </div>
</template>

<script>
import base from "@/mixins/base";
import selectSim from "../selectSim.vue";
import uploadOBS from "@/components/uploadOBS/uploadOBS";
import {
  findDownList,
  otherTaskCollection,
  getCarBrand,
  getCarColor
} from "@/api";
import { printError } from "@/utils/util";
import dialogMixins from "../mixins/index";
import addEquipFee from "../addEquipFee.vue";
export default {
  name: "reinstallDialog",
  components: { selectSim, addEquipFee, uploadOBS },
  data() {
    //自定义校验规则
    var checkIdvin = (rule, value, cb) => {
      const vinRegex = /[a-zA-Z0-9]{17}$/;
      if (vinRegex.test(value)) {
        return cb();
      }
      cb(new Error("只能输入数字或字母"));
    };

    return {
      checkIdvin,
      dialogLoading: false,
      auditForm: {
        finishTime: "", //作业时间
        sceneName: "", //	场景名称
        vin: "", //车架号
        vehicleBrand: "", //车辆品牌
        vehicleBrandName: "",
        insideLength: "", //	车厢长度（cm）
        overallLength: "", //	长
        overallWidth: "", //	宽
        overallHeight: "", //	高
        vehicleMarker: "", //
        plateColor: "", //车牌颜色id
        plateColorName: ""
      },
      colorList: [],
      carBrandList: [], //车辆品牌下拉框
      equipRow: {},
      equipOpList: [
        {
          codeValue: "1",
          codeName: "移除"
        },
        {
          codeValue: "0",
          codeName: "重装"
        }
      ], //设备操作类型
      cardOpList: [], //卡操作类型
      simList: [], //工程师名下sim卡
      simVisible: false,
      vehicleMarkerList: [],
      hiddenForm: false, //是否显示form信息
      /*******设备明细********/
      columnData: [
        {
          fieldName: "equipOp",
          display: true,
          fieldLabel: "设备操作",
          width: 160,
          disabled: false,
          fixed: true,
          align: "center",
          class: ""
        },
        {
          fieldName: "cardOp",
          display: true,
          fieldLabel: "卡操作",
          width: 160,
          disabled: false,
          fixed: true,
          align: "center",
          class: ""
        },
        {
          fieldName: "simNo",
          display: true,
          fieldLabel: "新SIM卡",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "oldSimNo",
          display: true,
          fieldLabel: "旧SIM卡号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "thirdClassName",
          display: true,
          fieldLabel: "商品名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "firstClassName",
          display: true,
          fieldLabel: "商品分类",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "secondClassName",
          display: true,
          fieldLabel: "商品开票名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "equCombination",
          display: true,
          fieldLabel: "安装组合",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "equipCode",
          display: true,
          fieldLabel: "新设备编号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "oldEquipCode",
          display: true,
          fieldLabel: "旧设备编号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },

        {
          fieldName: "commids",
          display: true,
          fieldLabel: "中心识别码",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        }
      ],
      /******* 换卡*******/
      changeCardVisible: false,
      /******换机*******/
      handleType: "", //设备操作类型

      /*******添加费用********/
      addFeeVisible: false
      // 更换设备
    };
  },
  props: ["basicInfo", "auditVisible"],
  mixins: [base, dialogMixins],
  watch: {
    auditVisible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.initData();
          this.getInstallDetail();
          this.getCredentialReq();
          this.auditForm.sceneAddress = this.basicInfo.sceneAddress;
          this.auditForm.sceneName = this.basicInfo.newSceneName;
          this.auditForm.sceneType = this.basicInfo.sceneType.toString();
          this.auditForm = {
            ...this.auditForm,
            ...this.basicInfo.taskItemOtherInfoRespVO
          };

          this.auditForm.vehicleMarker = this.auditForm.vehicleMarker
            ? this.auditForm.vehicleMarker.toString()
            : "";
          this.auditForm.vehicleBrand = this.auditForm.vehicleBrand
            ? this.auditForm.vehicleBrand
            : "";
          this.auditForm.plateColorName = this.basicInfo.plateColorName;
          this.auditForm.plateColor = this.basicInfo.plateColor
            ? this.basicInfo.plateColor
            : "";
          this.auditForm.finishTime = this.basicInfo.fixDateStr
            ? this.basicInfo.fixDateStr
            : "";
        }
      }
    }
    //车辆品牌
    // "auditForm.vehicleBrand": {
    //   immediate: true,
    //   handler(val) {
    //     if (val) {
    //       let vehicleBrandName = this.carBrandList.find(item => {
    //         return item.brandId == val;
    //       });
    //       this.auditForm.vehicleBrandNam =
    //         vehicleBrandName?.vehicleBrandName.brandName;
    //     }
    //   }
    // }
  },
  computed: {
    auditFormRules() {
      let rules = {
        finishTime: [
          {
            required: true,
            message: "请选择作业时间",
            trigger: ["blur", "change"]
          }
        ],
        sceneName: [
          {
            required: true,
            message: "请输入新场景名称",
            trigger: ["blur", "change"]
          },
          {
            min: 5,
            max: 14,
            message: "场景名称长度为5~14",
            trigger: "blur"
          }
        ],
        plateColor: [
          {
            required: true,
            message: "请选择车牌颜色",
            trigger: ["blur", "change"]
          }
        ],
        vehicleMarker: [
          {
            required: true,
            message: "请选择车型分类",
            trigger: ["blur", "change"]
          }
        ],
        overallLength: [
          {
            required: true,
            message: "请输入车辆长度",
            trigger: ["blur", "change"]
          }
        ],
        overallWidth: [
          {
            required: true,
            message: "请输入车辆宽度",
            trigger: ["blur", "change"]
          }
        ],
        overallHeight: [
          {
            required: true,
            message: "请输入车辆高度",
            trigger: ["blur", "change"]
          }
        ]
      };
      if (this.hiddenForm || this.basicInfo.isAllRemove == 1) {
        rules = {
          finishTime: [
            {
              required: true,
              message: "请选择作业时间",
              trigger: ["blur", "change"]
            }
          ]
        };
      }
      return rules;
    }
  },
  created() {},
  methods: {
    //选择车辆品牌
    handleVehicleBrand(flag, node) {
      this.auditForm.vehicleBrandName = node.label;
    },
    //选择车牌颜色
    handleColor(flag, node) {
      this.auditForm.plateColorName = node.label;
    },
    //选择车型分类
    handleVehicleMarker(flag, node) {
      this.auditForm.vehicleMarkerName = node.label;
    },
    //获取工程师所有信息
    async initData() {
      try {
        this.dialogLoading = true;
        let promiseList = [
          findDownList(["reloadCardOp", "vehicleType"]), //获取工程师名下sim卡
          //获取车辆品牌
          getCarBrand(),
          getCarColor()
        ];
        let [res, carBrandRes, colorRes] = await Promise.all(promiseList);
        //卡操作类型
        this.cardOpList = this.getFreezeResponse(res, {
          path: "data.reloadCardOp"
        });

        //车辆分类下拉框
        this.vehicleMarkerList = this.getFreezeResponse(res, {
          path: "data.vehicleType"
        });
        //车辆品牌下拉框
        this.carBrandList = this.getFreezeResponse(carBrandRes, {
          path: "data"
        });
        this.colorList = this.getFreezeResponse(colorRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //重置form
    resetForm() {
      this.auditForm = {
        finishTime: "", //作业时间
        sceneName: "", //	场景名称
        sceneType: "", //场景类型1车，2门店，3仓库
        vin: "", //车架号
        vehicleBrand: "", //车辆品牌
        vehicleBrandName: "",
        insideLength: "", //	车厢长度（cm）
        overallLength: "", //	长
        overallWidth: "", //	宽
        overallHeight: "", //	高
        vehicleMarker: "" //	车型分类(1普货厢车 2冷藏厢车 3半挂牵引车（普货） 4半挂牵引车（冷藏） 5危险品运输 其它)
      };
      this.equipDetailTableData = [];
      this.$refs.auditForm.resetFields();
      this.imgList = [];
    },
    //点击确定
    handleConfirm() {
      this.$refs.auditForm.validate(valid => {
        if (valid) {
          // let equipDetailTableData = this.equipDetailTableData;
          // equipDetailTableData.map(item => {
          //   if (item.newCardNo) {
          //     item.simNo = item.newCardNo;
          //     let simInfo = this.simList.find(each => {
          //       return each.simNo == item.newCardNo;
          //     });
          //     item.simId = simInfo.simId;
          //     item.isSelfOwn = simInfo.isPrivate;
          //   }
          //   this.equipDetailTableData = equipDetailTableData;
          // });
          let equipDetailTableData = this.equipDetailTableData;
          //主机设备
          let equipTypeList = equipDetailTableData.filter(item => {
            return item.equType == 1;
          });
          //检验sim卡位数
          let simRegx = /^(\d{11}|\d{13})$/;
          if (
            this.basicInfo.isGrayscaleWork &&
            equipTypeList.some(
              item =>
                (item.cardOp == 2 || item.cardOp == 3) &&
                !simRegx.test(item.simNo)
            )
          ) {
            this.$message.warning(
              "SIM卡号只能是由11位或13位纯数字组成，请修改后重新提交"
            );
            return;
          }
          if (
            !this.basicInfo.isGrayscaleWork &&
            equipTypeList.some(
              item => (item.cardOp == 2 || item.cardOp == 3) && !item.simNo
            )
          ) {
            this.$message.warning("换卡或装卡的设备必须选择SIM卡");
            return;
          }
          this.$confirm(
            "是否确认提交补录信息，提交后将不能再更改！",
            "补录信息确认",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            }
          )
            .then(() => {
              this.otherTaskCollection();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消"
              });
            });
        }
      });
    },
    //重装补录信息
    async otherTaskCollection() {
      try {
        this.dialogLoading = true;
        let supplementEuqReqVOList = this.equipDetailTableData.filter(item => {
          return item.equipOp == 0;
        });
        // let plateColorName = this.colorList.find(item => {
        //   return item.colorId == this.basicInfo.plateColor;
        // }).colorName;
        let res = await otherTaskCollection({
          ...this.auditForm,
          itemId: this.basicInfo.itemId,
          taskSecondClass: this.basicInfo.taskSecondClass,
          supplementEuqReqVOList,
          photoLocationReqVOList: this.imgList,
          sceneType: this.basicInfo.sceneType,
          versionNumber: this.basicInfo.versionNumber
        });
        if (res.code == "OK") {
          this.$message.success("操作成功");
          this.$emit("refresh");
          this.resetForm();
          this.handleClose();
          // eventBus.$emit("SHOW-DIALOG");
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    handleClose() {
      this.$emit("handleClose", "auditVisible");
    },
    /*****换卡操作*********/
    closeDialog(name) {
      this[name] = false;
    },
    //选中换卡
    handleCard(val, node, row) {
      if (row.equipOp == 2) {
        this.equipRow = row;
        // this.simVisible = true;
      } else {
        row.simNo = "";
        row.simId = "";
      }
    },
    //点击选择sim卡
    handleSim(row) {
      this.equipRow = row;
      this.simVisible = true;
    },
    selectSim(simData) {
      this.equipRow.simNo = simData.simNo;
      this.equipRow.simId = simData.simId;
      this.equipRow.isSelfOwn = simData.isSelfOwn;
      this.closeSim();
    },
    closeSim() {
      this.simVisible = false;
    },
    // updateCard(newCardNo) {
    //   this.equipRow.newCardNo = newCardNo;
    // },
    /******设备操作*******/
    handleEquip(row) {
      if (row.equType == 1 && row.equipOp == "1") {
        //如果为主机并且选中移除,配件都移除
        this.equipDetailTableData.map(item => {
          if (item.terminalId == row.terminalId) {
            item.equipOp = "1";
          }
        });
      }
      this.isShowForm();
    },
    //判断是否全部为移除
    isShowForm() {
      this.hiddenForm = this.equipDetailTableData.every(item => {
        return item.equipOp == "1";
      });
    },
    //更新费用
    updateFee(feeInfo) {
      this.equipRow.fixMoney = feeInfo.fixMoney; //金额
      this.equipRow.fixCatId = feeInfo.fixCatId; //条目ID
      this.equipRow.priceFrom = feeInfo.priceFrom;
      this.addFeeVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.audit-content {
  // margin-top: 20px;
  .title {
    padding-left: 20px;
    box-sizing: border-box;
    // margin-bottom: 15px;
    margin-top: 15px;
  }
  .detail-title {
    padding-left: 20px;
    box-sizing: border-box;
    margin-bottom: 15px;
    &::before {
      content: "*";
      color: #f56c6c;
      margin-right: 4px;
    }
  }
  .img-content {
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
    /deep/ .img-item {
      margin-top: 20px;
      margin-right: 15px;
      .avatar-uploader {
        .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          &:hover {
            border-color: #409eff;
          }
        }
        .avatar-uploader-icon {
          font-size: 28px;
          color: #8c939d;
          width: 110px;
          height: 90px;
          line-height: 90px;
          text-align: center;
        }
        .avatar {
          width: 110px;
          height: 90px;
          display: block;
        }
      }
      .img-title {
        width: 100%;
        text-align: center;
        margin-top: 5px;
      }
    }
  }
}
</style>
